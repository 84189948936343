(function () {
    angular.module('informaApp')
        .component('barChart', {
            templateUrl: 'components/charts/bar-chart/template.html',
            controller: BarChartController,
            bindings: {
                source: '<',
                metric: '<',
                options: '<',
                onRedraw: '<',
                hiddenPhases: '<'
            }
        });


    const PHASES_KEYS = ['i', 'ii', 'iii', 'nda'];
    const CHART_CONTAINER_SELECTOR = '.charty';
    const MAX_Y_OF_PERCENT = 100;

    const PREFIXES = {
        percent: '%',
        year: ' yr'
    };

    const METRICS_KEYS = {
        duration: 'Duration',
        loa: 'LOA',
        pos: 'PTS',
        medianDuration: 'MedianDuration'
    };

    function BarChartController($element, BarChartHelper, MetricsTypes) {
        const canvasElement = $element.find(CHART_CONTAINER_SELECTOR)[0];

        this.chart = new BarChartHelper.Chart(canvasElement);

        const metricMapping = getMetricMapping(MetricsTypes);

        this.$onChanges = () => {
            updateChart.call(this, metricMapping);
        };

        this.scrollForward = function () {
            this.chart.scrollForward();
        };

        this.scrollBackward = function () {
            this.chart.scrollBackward();
        };
    }

    function updateChart(metricMapping) {
        const metricOptions = metricMapping.find(x => x.metric === this.metric);

        const sets = getDatasetForChart(this.source, metricOptions.key, this.hiddenPhases);

        const settings = getChartSettingsByMetric(metricOptions, this.source);

        this.chart.update(getPhasesKeys(this.hiddenPhases), sets.pinnedSet, sets.scrollableSet, settings.max, settings.prefix, metricOptions.key, this.onRedraw);

        this.chart.showHideNumbers(this.options && this.options.showNumbers);
        this.chart.setXAxisVisibility(this.options && !this.options.hideXAxis);
    }

    function getChartSettingsByMetric(metricOptions, source) {
        return {
            prefix: metricOptions.prefix,
            max: metricOptions.getMax(source)
        };
    }

    function getDatasetForChart(source, metricKey, hiddenPhases) {
        if (source.sort === undefined) {
            return;
        }

        const mappedSource = sortSource(removeHiddenPhasesFromSource(source, metricKey, hiddenPhases));

        return {
            pinnedSet: mappedSource.filter(x => x.pinned),
            scrollableSet: mappedSource.filter(x => !x.pinned)
        };
    }

    function removeHiddenPhasesFromSource(source, metricKey, hiddenPhasesNumbers) {
        return source.map(item => {
            return {
                ...item,
                [metricKey]: item[metricKey].filter((phaseValue, index) => !hiddenPhasesNumbers.includes(index + 1))
            };
        });
    }

    function sortSource(source) {
        return source.sort((a, b) => {
            if (a.label === b.label) {
                return 0;
            }

            return a.label > b.label ? 1 : -1;
        });
    }

    function getMetricMapping(MetricsTypes) {
        const durationPrefix = PREFIXES.year;
        const percentPrefix = PREFIXES.percent;

        const getMaxForPercent = () => MAX_Y_OF_PERCENT;
        const getMaxForDuration = (source) => {
            const allValues = [].concat(...source.map(x => x.Duration));

            return Math.max(...allValues);
        };

        return [
            {
                metric: MetricsTypes.duration,
                key: METRICS_KEYS.duration,
                prefix: durationPrefix,
                getMax: getMaxForDuration
            }, {
                metric: MetricsTypes.loa,
                key: METRICS_KEYS.loa,
                prefix: percentPrefix,
                getMax: getMaxForPercent
            }, {
                metric: MetricsTypes.pos,
                key: METRICS_KEYS.pos,
                prefix: percentPrefix,
                getMax: getMaxForPercent
            }, {
                metric: MetricsTypes.medianDuration,
                key: METRICS_KEYS.medianDuration,
                prefix: durationPrefix,
                getMax: getMaxForDuration
            },
        ];
    }

    function getPhasesKeys(hiddenPhasesNumbers) {
        return PHASES_KEYS.filter((phaseKey, index) => !hiddenPhasesNumbers.includes(index + 1));
    }
})();
